exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-directexpress-js": () => import("./../../../src/pages/directexpress.js" /* webpackChunkName: "component---src-pages-directexpress-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-payperks-for-directexpress-2021-transition-js": () => import("./../../../src/pages/payperks-for-directexpress-2021-transition.js" /* webpackChunkName: "component---src-pages-payperks-for-directexpress-2021-transition-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-posts-2020-01-treasury-reawards-direct-express-to-comerica-featuring-payperks-mdx": () => import("./../../../src/posts/2020-01-treasury-reawards-direct-express-to-comerica-featuring-payperks.mdx" /* webpackChunkName: "component---src-posts-2020-01-treasury-reawards-direct-express-to-comerica-featuring-payperks-mdx" */),
  "component---src-posts-2020-04-meet-don-payperks-for-direct-express-profile-mdx": () => import("./../../../src/posts/2020-04-meet-don-payperks-for-direct-express-profile.mdx" /* webpackChunkName: "component---src-posts-2020-04-meet-don-payperks-for-direct-express-profile-mdx" */),
  "component---src-posts-2020-04-meet-helen-direct-express-cardholder-financial-capability-payperks-mdx": () => import("./../../../src/posts/2020-04-meet-helen-direct-express-cardholder-financial-capability-payperks.mdx" /* webpackChunkName: "component---src-posts-2020-04-meet-helen-direct-express-cardholder-financial-capability-payperks-mdx" */),
  "component---src-posts-a-look-back-and-a-peek-forward-mdx": () => import("./../../../src/posts/a-look-back-and-a-peek-forward.mdx" /* webpackChunkName: "component---src-posts-a-look-back-and-a-peek-forward-mdx" */),
  "component---src-posts-aga-reports-prepaid-card-savings-mdx": () => import("./../../../src/posts/aga-reports-prepaid-card-savings.mdx" /* webpackChunkName: "component---src-posts-aga-reports-prepaid-card-savings-mdx" */),
  "component---src-posts-american-express-features-payperks-in-new-video-series-spotlightonchange-mdx": () => import("./../../../src/posts/american-express-features-payperks-in-new-video-series-spotlightonchange.mdx" /* webpackChunkName: "component---src-posts-american-express-features-payperks-in-new-video-series-spotlightonchange-mdx" */),
  "component---src-posts-bowdoin-magazine-features-payperks-ceo-and-her-brother-mdx": () => import("./../../../src/posts/bowdoin-magazine-features-payperks-ceo-and-her-brother.mdx" /* webpackChunkName: "component---src-posts-bowdoin-magazine-features-payperks-ceo-and-her-brother-mdx" */),
  "component---src-posts-center-for-american-progress-highlights-payperks-as-a-financial-capability-tool-mdx": () => import("./../../../src/posts/center-for-american-progress-highlights-payperks-as-a-financial-capability-tool.mdx" /* webpackChunkName: "component---src-posts-center-for-american-progress-highlights-payperks-as-a-financial-capability-tool-mdx" */),
  "component---src-posts-cfed-conference-mdx": () => import("./../../../src/posts/cfed-conference.mdx" /* webpackChunkName: "component---src-posts-cfed-conference-mdx" */),
  "component---src-posts-cfsi-and-cores-map-of-payments-mdx": () => import("./../../../src/posts/cfsi-and-cores-map-of-payments.mdx" /* webpackChunkName: "component---src-posts-cfsi-and-cores-map-of-payments-mdx" */),
  "component---src-posts-cfsi-and-jpmc-launch-financial-solutions-lab-mdx": () => import("./../../../src/posts/cfsi-and-jpmc-launch-financial-solutions-lab.mdx" /* webpackChunkName: "component---src-posts-cfsi-and-jpmc-launch-financial-solutions-lab-mdx" */),
  "component---src-posts-cfsi-interview-with-arlyn-mdx": () => import("./../../../src/posts/cfsi-interview-with-arlyn.mdx" /* webpackChunkName: "component---src-posts-cfsi-interview-with-arlyn-mdx" */),
  "component---src-posts-cfsi-underbanked-forum-mdx": () => import("./../../../src/posts/cfsi-underbanked-forum.mdx" /* webpackChunkName: "component---src-posts-cfsi-underbanked-forum-mdx" */),
  "component---src-posts-connect-with-us-on-facebook-twitter-and-linkedin-mdx": () => import("./../../../src/posts/connect-with-us-on-facebook-twitter-and-linkedin.mdx" /* webpackChunkName: "component---src-posts-connect-with-us-on-facebook-twitter-and-linkedin-mdx" */),
  "component---src-posts-core-innovators-mega-challenge-payperks-wins-mdx": () => import("./../../../src/posts/core-innovators-mega-challenge-payperks-wins.mdx" /* webpackChunkName: "component---src-posts-core-innovators-mega-challenge-payperks-wins-mdx" */),
  "component---src-posts-direct-express-mobile-app-launch-with-payperks-mdx": () => import("./../../../src/posts/direct-express-mobile-app-launch-with-payperks.mdx" /* webpackChunkName: "component---src-posts-direct-express-mobile-app-launch-with-payperks-mdx" */),
  "component---src-posts-driving-behavior-change-philly-feds-payment-card-center-mdx": () => import("./../../../src/posts/driving-behavior-change-philly-feds-payment-card-center.mdx" /* webpackChunkName: "component---src-posts-driving-behavior-change-philly-feds-payment-card-center-mdx" */),
  "component---src-posts-federal-reserve-bank-of-philadelphia-publishes-white-paper-on-payperks-mdx": () => import("./../../../src/posts/federal-reserve-bank-of-philadelphia-publishes-white-paper-on-payperks.mdx" /* webpackChunkName: "component---src-posts-federal-reserve-bank-of-philadelphia-publishes-white-paper-on-payperks-mdx" */),
  "component---src-posts-financial-services-landscape-distilled-into-brilliant-infographic-at-money-2020-mdx": () => import("./../../../src/posts/financial-services-landscape-distilled-into-brilliant-infographic-at-money2020.mdx" /* webpackChunkName: "component---src-posts-financial-services-landscape-distilled-into-brilliant-infographic-at-money-2020-mdx" */),
  "component---src-posts-fox-business-news-interviews-payperks-ceo-mdx": () => import("./../../../src/posts/fox-business-news-interviews-payperks-ceo.mdx" /* webpackChunkName: "component---src-posts-fox-business-news-interviews-payperks-ceo-mdx" */),
  "component---src-posts-happy-holidays-and-happy-new-year-mdx": () => import("./../../../src/posts/happy-holidays-and-happy-new-year.mdx" /* webpackChunkName: "component---src-posts-happy-holidays-and-happy-new-year-mdx" */),
  "component---src-posts-live-updates-from-the-underbanked-financial-services-forum-mdx": () => import("./../../../src/posts/live-updates-from-the-underbanked-financial-services-forum.mdx" /* webpackChunkName: "component---src-posts-live-updates-from-the-underbanked-financial-services-forum-mdx" */),
  "component---src-posts-mastercard-announces-payperks-for-public-sector-at-the-white-house-mdx": () => import("./../../../src/posts/mastercard-announces-payperks-for-public-sector-at-the-white-house.mdx" /* webpackChunkName: "component---src-posts-mastercard-announces-payperks-for-public-sector-at-the-white-house-mdx" */),
  "component---src-posts-mastercard-partners-in-prepaid-mdx": () => import("./../../../src/posts/mastercard-partners-in-prepaid.mdx" /* webpackChunkName: "component---src-posts-mastercard-partners-in-prepaid-mdx" */),
  "component---src-posts-money-2020-panel-features-payperks-as-an-innovative-solution-for-the-financially-underserved-mdx": () => import("./../../../src/posts/money2020-panel-features-payperks-as-an-innovative-solution-for-the-financially-underserved.mdx" /* webpackChunkName: "component---src-posts-money-2020-panel-features-payperks-as-an-innovative-solution-for-the-financially-underserved-mdx" */),
  "component---src-posts-more-than-fun-and-games-mdx": () => import("./../../../src/posts/more-than-fun-and-games.mdx" /* webpackChunkName: "component---src-posts-more-than-fun-and-games-mdx" */),
  "component---src-posts-my-first-payperks-fountain-jump-mdx": () => import("./../../../src/posts/my-first-payperks-fountain-jump.mdx" /* webpackChunkName: "component---src-posts-my-first-payperks-fountain-jump-mdx" */),
  "component---src-posts-nadeem-shaikh-talks-about-our-financial-future-digitized-at-tedx-lbs-2-mdx": () => import("./../../../src/posts/nadeem-shaikh-talks-about-our-financial-future-digitized-at-tedx-lbs-2.mdx" /* webpackChunkName: "component---src-posts-nadeem-shaikh-talks-about-our-financial-future-digitized-at-tedx-lbs-2-mdx" */),
  "component---src-posts-next-up-payperks-to-help-improve-nutrition-capabilities-mdx": () => import("./../../../src/posts/next-up-payperks-to-help-improve-nutrition-capabilities.mdx" /* webpackChunkName: "component---src-posts-next-up-payperks-to-help-improve-nutrition-capabilities-mdx" */),
  "component---src-posts-pay-perks-makes-a-mark-on-the-world-with-devin-thorpe-mdx": () => import("./../../../src/posts/PayPerks-makes-a-mark-on-the-world-with-Devin-Thorpe.mdx" /* webpackChunkName: "component---src-posts-pay-perks-makes-a-mark-on-the-world-with-devin-thorpe-mdx" */),
  "component---src-posts-paybefore-and-bank-credit-news-highlight-payperks-successful-launch-with-directexpress-mdx": () => import("./../../../src/posts/paybefore-and-bank-credit-news-highlight-payperks-successful-launch-with-directexpress.mdx" /* webpackChunkName: "component---src-posts-paybefore-and-bank-credit-news-highlight-payperks-successful-launch-with-directexpress-mdx" */),
  "component---src-posts-paybefore-awards-honor-payperks-as-the-one-to-watch-mdx": () => import("./../../../src/posts/paybefore-awards-honor-payperks-as-the-one-to-watch.mdx" /* webpackChunkName: "component---src-posts-paybefore-awards-honor-payperks-as-the-one-to-watch-mdx" */),
  "component---src-posts-paybefore-praises-payperks-model-in-gamification-article-mdx": () => import("./../../../src/posts/paybefore-praises-payperks-model-in-gamification-article.mdx" /* webpackChunkName: "component---src-posts-paybefore-praises-payperks-model-in-gamification-article-mdx" */),
  "component---src-posts-paybefore-selects-payperks-as-this-months-company-to-watch-mdx": () => import("./../../../src/posts/paybefore-selects-payperks-as-this-months-company-to-watch.mdx" /* webpackChunkName: "component---src-posts-paybefore-selects-payperks-as-this-months-company-to-watch-mdx" */),
  "component---src-posts-payperks-ceo-arlyn-davich-writes-oped-on-the-benefits-of-prepaid-cards-mdx": () => import("./../../../src/posts/payperks-ceo-arlyn-davich-writes-oped-on-the-benefits-of-prepaid-cards.mdx" /* webpackChunkName: "component---src-posts-payperks-ceo-arlyn-davich-writes-oped-on-the-benefits-of-prepaid-cards-mdx" */),
  "component---src-posts-payperks-ceo-in-federal-reserve-bank-st-louis-webinar-mdx": () => import("./../../../src/posts/payperks-ceo-in-federal-reserve-bank-st-louis-webinar.mdx" /* webpackChunkName: "component---src-posts-payperks-ceo-in-federal-reserve-bank-st-louis-webinar-mdx" */),
  "component---src-posts-payperks-co-founder-cto-jake-peters-speaks-on-financial-inclusion-panel-at-the-money-event-mdx": () => import("./../../../src/posts/payperks-co-founder-cto-jake-peters-speaks-on-financial-inclusion-panel-at-the-money-event.mdx" /* webpackChunkName: "component---src-posts-payperks-co-founder-cto-jake-peters-speaks-on-financial-inclusion-panel-at-the-money-event-mdx" */),
  "component---src-posts-payperks-directexpress-dawn-profile-mdx": () => import("./../../../src/posts/payperks-directexpress-dawn-profile.mdx" /* webpackChunkName: "component---src-posts-payperks-directexpress-dawn-profile-mdx" */),
  "component---src-posts-payperks-directexpress-ray-profile-mdx": () => import("./../../../src/posts/payperks-directexpress-ray-profile.mdx" /* webpackChunkName: "component---src-posts-payperks-directexpress-ray-profile-mdx" */),
  "component---src-posts-payperks-featured-in-the-prize-linked-savings-connection-mdx": () => import("./../../../src/posts/payperks-featured-in-the-prize-linked-savings-connection.mdx" /* webpackChunkName: "component---src-posts-payperks-featured-in-the-prize-linked-savings-connection-mdx" */),
  "component---src-posts-payperks-founders-present-at-anthemis-innovation-playground-in-london-mdx": () => import("./../../../src/posts/payperks-founders-present-at-anthemis-innovation-playground-in-london.mdx" /* webpackChunkName: "component---src-posts-payperks-founders-present-at-anthemis-innovation-playground-in-london-mdx" */),
  "component---src-posts-payperks-fountain-jump-number-2-mdx": () => import("./../../../src/posts/payperks-fountain-jump-number-2.mdx" /* webpackChunkName: "component---src-posts-payperks-fountain-jump-number-2-mdx" */),
  "component---src-posts-payperks-is-the-next-big-thing-mdx": () => import("./../../../src/posts/payperks-is-the-next-big-thing.mdx" /* webpackChunkName: "component---src-posts-payperks-is-the-next-big-thing-mdx" */),
  "component---src-posts-payperks-named-best-newcomer-in-payments-mdx": () => import("./../../../src/posts/payperks-named-best-newcomer-in-payments.mdx" /* webpackChunkName: "component---src-posts-payperks-named-best-newcomer-in-payments-mdx" */),
  "component---src-posts-payperks-named-one-to-watch-finalist-mdx": () => import("./../../../src/posts/payperks-named-one-to-watch-finalist.mdx" /* webpackChunkName: "component---src-posts-payperks-named-one-to-watch-finalist-mdx" */),
  "component---src-posts-payperks-presents-at-the-ny-fintech-startups-meetup-mdx": () => import("./../../../src/posts/payperks-presents-at-the-ny-fintech-startups-meetup.mdx" /* webpackChunkName: "component---src-posts-payperks-presents-at-the-ny-fintech-startups-meetup-mdx" */),
  "component---src-posts-payperks-turns-government-payments-into-a-teachable-moment-mdx": () => import("./../../../src/posts/payperks-turns-government-payments-into-a-teachable-moment.mdx" /* webpackChunkName: "component---src-posts-payperks-turns-government-payments-into-a-teachable-moment-mdx" */),
  "component---src-posts-poets-and-quants-names-payperks-to-top-mba-startups-mdx": () => import("./../../../src/posts/poets-and-quants-names-payperks-to-top-mba-startups.mdx" /* webpackChunkName: "component---src-posts-poets-and-quants-names-payperks-to-top-mba-startups-mdx" */),
  "component---src-posts-poverty-and-the-unbanked-mdx": () => import("./../../../src/posts/poverty-and-the-unbanked.mdx" /* webpackChunkName: "component---src-posts-poverty-and-the-unbanked-mdx" */),
  "component---src-posts-prepaid-and-financial-capability-closer-together-paybefore-mdx": () => import("./../../../src/posts/prepaid-and-financial-capability-closer-together-paybefore.mdx" /* webpackChunkName: "component---src-posts-prepaid-and-financial-capability-closer-together-paybefore-mdx" */),
  "component---src-posts-press-sfgate-calculates-what-would-happen-if-all-of-sf-went-paperless-mdx": () => import("./../../../src/posts/press-sfgate-calculates-what-would-happen-if-all-of-sf-went-paperless.mdx" /* webpackChunkName: "component---src-posts-press-sfgate-calculates-what-would-happen-if-all-of-sf-went-paperless-mdx" */),
  "component---src-posts-press-usa-today-snapshot-on-savings-from-electronic-payroll-mdx": () => import("./../../../src/posts/press-usa-today-snapshot-on-savings-from-electronic-payroll.mdx" /* webpackChunkName: "component---src-posts-press-usa-today-snapshot-on-savings-from-electronic-payroll-mdx" */),
  "component---src-posts-recent-press-on-the-unbanked-mdx": () => import("./../../../src/posts/recent-press-on-the-unbanked.mdx" /* webpackChunkName: "component---src-posts-recent-press-on-the-unbanked-mdx" */),
  "component---src-posts-report-day-1-at-the-prepaid-expo-mdx": () => import("./../../../src/posts/report-day-1-at-the-prepaid-expo.mdx" /* webpackChunkName: "component---src-posts-report-day-1-at-the-prepaid-expo-mdx" */),
  "component---src-posts-report-day-2-at-the-prepaid-expo-mdx": () => import("./../../../src/posts/report-day-2-at-the-prepaid-expo.mdx" /* webpackChunkName: "component---src-posts-report-day-2-at-the-prepaid-expo-mdx" */),
  "component---src-posts-robs-first-day-like-rain-on-your-wedding-day-mdx": () => import("./../../../src/posts/robs-first-day-like-rain-on-your-wedding-day.mdx" /* webpackChunkName: "component---src-posts-robs-first-day-like-rain-on-your-wedding-day-mdx" */),
  "component---src-posts-shibani-joshi-fox-business-news-payperks-ceo-arlyn-davich-mdx": () => import("./../../../src/posts/shibani-joshi-fox-business-news-payperks-ceo-arlyn-davich.mdx" /* webpackChunkName: "component---src-posts-shibani-joshi-fox-business-news-payperks-ceo-arlyn-davich-mdx" */),
  "component---src-posts-speaking-at-the-innovation-playground-for-financial-services-summit-mdx": () => import("./../../../src/posts/speaking-at-the-innovation-playground-for-financial-services-summit.mdx" /* webpackChunkName: "component---src-posts-speaking-at-the-innovation-playground-for-financial-services-summit-mdx" */),
  "component---src-posts-team-payperks-bowls-and-bowls-night-mdx": () => import("./../../../src/posts/team-payperks-bowls-and-bowls-night.mdx" /* webpackChunkName: "component---src-posts-team-payperks-bowls-and-bowls-night-mdx" */),
  "component---src-posts-team-payperks-has-grown-mdx": () => import("./../../../src/posts/team-payperks-has-grown.mdx" /* webpackChunkName: "component---src-posts-team-payperks-has-grown-mdx" */),
  "component---src-posts-the-weird-thing-about-momentum-mdx": () => import("./../../../src/posts/the-weird-thing-about-momentum.mdx" /* webpackChunkName: "component---src-posts-the-weird-thing-about-momentum-mdx" */),
  "component---src-posts-whats-the-difference-between-financial-education-and-flossing-a-socap-recap-mdx": () => import("./../../../src/posts/whats-the-difference-between-financial-education-and-flossing-a-SOCAP-recap.mdx" /* webpackChunkName: "component---src-posts-whats-the-difference-between-financial-education-and-flossing-a-socap-recap-mdx" */),
  "component---src-posts-will-the-banks-agree-to-offer-accounts-without-fees-mdx": () => import("./../../../src/posts/will-the-banks-agree-to-offer-accounts-without-fees.mdx" /* webpackChunkName: "component---src-posts-will-the-banks-agree-to-offer-accounts-without-fees-mdx" */)
}


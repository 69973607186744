const HOME_URL = "/"

const LEGAL_INDEX_URL = "/legal"
const LEGAL_PRIVACY_URL = `${LEGAL_INDEX_URL}/privacy`
const LEGAL_TERMS_URL = `${LEGAL_INDEX_URL}/terms`

const ABOUT_URL = "/about"
const BLOG_HOME_URL = "/blog"
const IMPACT_URL = "/impact"
const SOLUTIONS_URL = "/solutions"
const PROGRAMS_URL = `${SOLUTIONS_URL}/programs`

// PayPerks for DE
const PAYPERKS_DE_TRANSITION_URL = "/directexpress"
const OLD__PAYPERKS_DE_TRANSITION_URL =
  "/payperks-for-directexpress-2021-transition"
const PAYPERKS_DE_LEGAL_SWEEPSTAKES_RULES_URL = "/directexpress"

// External

// SMI
const SMI_ONE_CARD_HOME_URL = "https://www.smionecard.com/"
const SMI_CORP_URL = "https://www.smi-inc.com/"
const SMI_PAYPERKS_RELEASE_URL =
  "https://www.smi-inc.com/news/smi-acquires-payperks-for-smione-cardholders"

// Direct Express
const DIRECT_EXPRESS_INFO_URL = "https://directexpress.info/"
const DIRECT_EXPRESS_WEBSITE_URL = "https://www.usdirectexpress.com/"
const HELP_CENTER_URL = "https://help.payperks.com/hc/en-us"

// NJ MoneySkills
const NJ_DCF_URL = "https://www.nj.gov/dcf/"
const NJ_MONEYSKILLS_URL = "https://www.njmoneyskills.com/"
const NJ_MONEYSKILLS_URL_WITH_PARAMS = `${NJ_MONEYSKILLS_URL}?utm_medium=website&utm_source=payperks&utm_campaign=programs&utm_content=cta-visit-the-site`

// VA FoodSmarts
const SHARE_OUR_STRENGTH_URL = "https://www.shareourstrength.org/"
const COOKING_MATTERS_URL = "https://cookingmatters.org/"

// ES
const PAYPERKS_ES_HOME_URL = "https://es.payperks.com"

// Socials
const PAYPERKS_FACEBOOK_URL = "https://www.facebook.com/payperks/"
const PAYPERKS_LINKEDIN_URL = "https://www.linkedin.com/company/payperks/"
const PAYPERKS_TWITTER_URL = "https://twitter.com/payperks"

export {
  ABOUT_URL,
  BLOG_HOME_URL,
  COOKING_MATTERS_URL,
  DIRECT_EXPRESS_INFO_URL,
  DIRECT_EXPRESS_WEBSITE_URL,
  HELP_CENTER_URL,
  HOME_URL,
  IMPACT_URL,
  LEGAL_INDEX_URL,
  LEGAL_PRIVACY_URL,
  LEGAL_TERMS_URL,
  NJ_DCF_URL,
  NJ_MONEYSKILLS_URL_WITH_PARAMS,
  OLD__PAYPERKS_DE_TRANSITION_URL,
  PAYPERKS_DE_TRANSITION_URL,
  PAYPERKS_ES_HOME_URL,
  PAYPERKS_DE_LEGAL_SWEEPSTAKES_RULES_URL,
  PAYPERKS_FACEBOOK_URL,
  PAYPERKS_LINKEDIN_URL,
  PAYPERKS_TWITTER_URL,
  SHARE_OUR_STRENGTH_URL,
  SMI_ONE_CARD_HOME_URL,
  SMI_CORP_URL,
  SMI_PAYPERKS_RELEASE_URL,
  SOLUTIONS_URL,
  PROGRAMS_URL,
}
